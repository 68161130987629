#swift-sales-widget {
    background-color: transparent;
    z-index: 99999999;
    max-height: 120px;
    position: fixed;
    bottom: 0px;
    border: 0px;
    width: 450px;
    height: 670px;
    max-width: 120px;
    overflow: hidden;
    opacity: 1;
    right: 0px;
}

.swift-sales-widget-integrate{
    width: 100% !important;
    height: 100% !important;
}
#swift-sales-widget-iframe {
    pointer-events: all;
    background: none;
    border: 0px;
    float: none;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.fancy-scroll {
    overflow: hidden;
    overflow-y: auto;
}

.fancy-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0);
    border-radius: 1rem;
    background-color: inherit;
}
.fancy-scroll::-webkit-scrollbar {
    width: 0.6rem;
    /* background-color: #f5f5f5;*/
}
.fancy-scroll::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0);
    background-color: #89898961;
}
.fancy-scroll.auto::-webkit-scrollbar-thumb {
    background-color: #89898900;
}
.fancy-scroll.auto:hover::-webkit-scrollbar-thumb {
    background-color: #89898961;
}

.smooth-scroll {
    scroll-behavior: smooth;
}

/* #longDriveAgency-iframe {
    height: 670px !important;
} */
