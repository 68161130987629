.att-btn input {
    display: none;
}
.att-btn label {
    border-radius: 3px;
    background-color: #1d3152;
    padding: 10px 33px;
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}
.att-btn label:hover {
    background-color: #246b47;
    border-color: #246b47;
}

.as-complete-modal h3 {
    color: #1d3152;
    font-family: "Nunito", sans-serif;
    font-size: 19px;
    font-weight: 800;
}
.as-complete-modal p {
    color: #000;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    padding-bottom: 5px;
}
.as-complete-modal .modal-header {
    border: 0;
    padding: 0px;
}
.as-complete-modal .modal-footer {
    border: 0;
    justify-content: center;
    padding: 0px;
}
.as-complete-modal button {
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #1d3152;
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    transition: all ease-in-out 0.3s;
    height: auto;
    padding: 0 5px;
}
/* .as-complete-modal button:hover {
    background-color: #246b47;
    border-color: #246b47;
    color: #fff;
} */
.as-complete-modal button.btn-primary:disabled {
    border-radius: 3px;
    background-color: #a7b9c5;
    padding: 6px 21px;
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    border-color: #a7b9c5;
    opacity: 1;
}
.as-complete-modal button.btn-close {
    padding: 0;
    background-color: transparent;
    color: #748997;
    opacity: 1;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all ease-in-out 0.3s;
    filter: invert(62%) sepia(26%) saturate(280%) hue-rotate(161deg) brightness(81%) contrast(85%);
}
.as-complete-modal button.btn-close:hover {
    filter: invert(13%) sepia(72%) saturate(806%) hue-rotate(185deg) brightness(91%) contrast(89%);
}
.as-complete-modal .modal-content {
    border: 1px solid #dee4e7;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 6px 6px 16px 1px rgba(0, 0, 0, 0.18);
    /* height: 229px; */
    padding: 15px;
    text-align: center;
}
.as-complete-modal .modal-dialog.modal-dialog-centered {
    max-width: 150px;
    position: relative;
    z-index: 9999999999;
    right: 0;
    margin: auto;
    top: 186px;
    left: 10px;
}
.as-complete-modal-mobile .modal-dialog-centered{
    left: -15px !important;
}
.as-complete-modal .modal-body {
    padding: 0px;
}
.auto-comp-modal .modal-content {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18), 0 -2px 6px 4px rgba(0, 0, 0, 0.05);
    height: 170px;
    border: 0;
    border-top: 12.39px solid #246b47;
    padding: 25px 30px;
}
.auto-comp-modal .modal-dialog.modal-dialog-centered {
    max-width: 464px;
}
.auto-comp-modal .checkicon-sec {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.auto-comp-modal .checkicon-sec span {
    border: 3px solid #c6d0d5;
    height: 20.82px;
    width: 20.81px;
    display: block;
    border-radius: 50%;
}
.auto-comp-modal .checkicon-sec svg {
    font-size: 36px;
    color: #246b47;
    position: absolute;
    top: -9px;
    line-height: normal;
    height: auto;
    padding-left: 4px;
}
.auto-comp-modal p {
    color: #344c60;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.auto-comp-modal button {
    color: #638ccf;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    padding: 0;
    border: 0;
    text-decoration: underline;
}
.auto-comp-modal button:hover {
    background: transparent;
    color: #246b47;
}
.auto-comp-modal button:focus {
    color: #638ccf;
    background: transparent;
    outline: none !important;
    box-shadow: none;
}
.auto-comp-modal .modal-footer {
    margin-top: 6px;
}
.greenBtn {
    background-color: #246b47 !important;
    border-color: #246b47 !important;
}
